export type TrackSearchedRecommendedTermsFunction = (args: {
    query: string;
    source: string;
    returnedRecommendedTerms?: (string | null)[];
}) => void;

/**
 * Track event when a Member executes a search query for recommended terms
 * @see https://segment.com/docs/connections/spec/ecommerce/v2/#recommended-terms-searched
 */
const trackSearchedRecommendedTerms: TrackSearchedRecommendedTermsFunction = ({
    query,
    returnedRecommendedTerms,
    source,
}) => {
    let referrer;
    if (sessionStorage) {
        referrer = sessionStorage.getItem('referrer') || undefined;
    }
    if (window && window.analytics) {
        window.analytics.track('Recommended Terms Searched', {
            query,
            returnedRecommendedTerms,
            referrer,
            source,
        });
    }
};

export { trackSearchedRecommendedTerms };
