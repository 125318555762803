export type SearchSuggestionClickedFunction = (args: {
    query: string;
    queryId: string | undefined;
    source: string;
}) => void;

/**
 * Track event when a Member executes a search query
 * @see https://segment.com/docs/connections/spec/ecommerce/v2/#products-searched
 */
const trackSearchSuggestionClicked: SearchSuggestionClickedFunction = ({
    query,
    queryId,
    source,
}) => {
    let referrer;
    if (sessionStorage) {
        referrer = sessionStorage.getItem('referrer') || undefined;
    }
    if (window && window.analytics) {
        window.analytics.track('Search Suggestion Clicked', {
            query,
            queryId,
            referrer,
            source,
        });
    }
};

export { trackSearchSuggestionClicked };
