import { CircularProgress, Stack, Typography } from '@mui/material';

const SearchbarLoading: React.FC = () => {
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >
            <CircularProgress size={20} />
            <Typography>Loading...</Typography>
        </Stack>
    );
};

export { SearchbarLoading };
