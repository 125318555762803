import { noop } from 'lodash';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo } from 'react';

import {
    type UseProductSheetsResult,
    useProductSheets,
} from '@custom-hooks/sheets/useProductSheets';

const emptyDefault: UseProductSheetsResult = {
    activeProductSheet: null,
    currentProduct: null,
    showProductDetailsSheet: noop,
    showProductPreferencesSheet: noop,
    hideProductSheet: noop,
};

const ProductSheetContext =
    React.createContext<UseProductSheetsResult>(emptyDefault);

const useProductSheetContext = () => useContext(ProductSheetContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProductSheetProvider = ({ children }: any) => {
    const {
        activeProductSheet,
        currentProduct,
        hideProductSheet,
        showProductDetailsSheet,
        showProductPreferencesSheet,
    } = useProductSheets();
    const { pathname } = useRouter();

    useEffect(() => {
        hideProductSheet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const contextProviderValue = useMemo(
        () => ({
            activeProductSheet,
            currentProduct,
            hideProductSheet,
            showProductDetailsSheet,
            showProductPreferencesSheet,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeProductSheet, currentProduct],
    );

    return (
        <ProductSheetContext.Provider value={contextProviderValue}>
            {children}
        </ProductSheetContext.Provider>
    );
};

export { ProductSheetProvider, useProductSheetContext };
