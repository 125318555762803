import { useRouter } from 'next/router';
import Logo from 'public/images/logo-line.svg';
import { ReactNode } from 'react';

import { OnboardingButton } from '@scenes/Onboarding/components/button/onboarding/onboardingButton';

import styles from './ErrorPage.module.scss';

type ErrorPageProps = {
    title: string;
    message: ReactNode;
};

export function ErrorPage(props: ErrorPageProps) {
    const router = useRouter();

    return (
        <>
            <div className={styles.header}>
                <Logo className={styles.logo} />
            </div>

            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.contentContainer}>
                        <img
                            src="/images/phil-lost.svg"
                            alt="Lost Phil"
                            className={styles.illustration}
                        />

                        <h1 className={styles.title}>{props.title}</h1>
                        <p className={styles.message}>{props.message}</p>
                    </div>

                    <div className={styles.cta}>
                        <OnboardingButton
                            id="error-page-button"
                            onClick={() => {
                                router.replace('/');
                            }}
                            variant="charcoal"
                        >
                            TAKE ME HOME
                        </OnboardingButton>
                    </div>
                </div>
            </div>
        </>
    );
}
