import { useCallback, useState } from 'react';

import { ProductV2 } from '@gql/graphql';

interface UseProductSheetsResult {
    activeProductSheet: 'preferences' | 'details' | null;
    currentProduct: ProductV2 | null;
    hideProductSheet: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showProductDetailsSheet: (product: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showProductPreferencesSheet: (product: any) => void;
}

/**
 * useProductSheets – this is primarily used by useProductSheetContext(), which
 * is probably what you want to use to access the global state for active
 * product sheet!
 */
const useProductSheets = (): UseProductSheetsResult => {
    type ProductSheetType = 'preferences' | 'details';
    const [activeProductSheet, setActiveProductSheet] =
        useState<ProductSheetType | null>(null);

    const [currentProduct, setCurrentProduct] = useState<ProductV2 | null>(
        null,
    );

    const showProductDetailsSheet = useCallback((product: ProductV2) => {
        setActiveProductSheet('details');
        setCurrentProduct(product);
    }, []);

    const showProductPreferencesSheet = useCallback((product: ProductV2) => {
        setActiveProductSheet('preferences');
        setCurrentProduct(product);
    }, []);

    const hideProductSheet = useCallback(() => {
        setCurrentProduct(null);
        setActiveProductSheet(null);
    }, []);

    return {
        activeProductSheet,
        currentProduct,
        showProductDetailsSheet,
        showProductPreferencesSheet,
        hideProductSheet,
    };
};

export { useProductSheets, type UseProductSheetsResult };
