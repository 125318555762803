import { TrackId } from '@scenes/Onboarding/types';

export const SegmentOnboardingStarted = (
    onboardingTrack: TrackId,
    referralCode: string | string[] | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    utm: any,
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window?.analytics?.track(
        'Onboarding Started',
        {
            onboardingTrack,
            referralCode,
        },
        utm,
    );
};
