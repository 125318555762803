import { TrackId } from '@scenes/Onboarding/types';

export const trackOnboardingStepCompleted = async (
    step: string,
    trackId?: TrackId,
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Onboarding Step Completed', {
            onboardingTrack: trackId,
            step,
        });
};
