export const OnboardingSelectProductUserActions = {
    ADD_USER_ACTION: 'ADD',
    REMOVE_USER_ACTION: 'REMOVE',
};
export type Currency = 'USD';
export const USD_CURRENCY: Currency = 'USD';

export const STEP_TRACKING_IDS: Record<string, string> = {
    ACCOUNT_CREATION: 'account creation',
    ADDRESS_CHECK: 'location submit',
    ADDRESS_CHECK_RESULT: 'welcome',
    CHECKOUT: 'payment receipt',
    DELIVERY_INFO: 'delivery info',
    PERSONAL_DETAILS: 'personal details',
    VALUE_PROPOSITIONS: 'service description',
    WAITLIST: 'waitlist',

    // experimental:
    AUTOPILOT_PREVIEW: 'autopilot',
    REFILL_DAY: 'refill day',
};
