import { LayoutHeaderActions } from '../../../layout/layoutHeader';
import $mobileHeaderSearchResults from './mobileHeaderSearchResults.module.scss';

export interface MobileHeaderSearchResultsProps {
    label: string;
}

export const MobileHeaderSearchResultsHeight =
    $mobileHeaderSearchResults.containerHeight;

const MobileHeaderSearchResults = ({
    label,
}: MobileHeaderSearchResultsProps) => {
    return (
        <div className={$mobileHeaderSearchResults.container}>
            <LayoutHeaderActions headerText={label} />
        </div>
    );
};

export { MobileHeaderSearchResults };
