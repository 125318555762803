import clsx from 'clsx';

import $typographyNew from '@styles/typography.module.scss';

export const textClassNames = [
    'headline1',
    'headline2',
    'headline3',
    'headline4',
    'headline5',
    'body1regular',
    'body2regular',
    'body3regular',
    'body1medium',
    'body2medium',
    'body3medium',
    'body1bold',
    'body2bold',
    'body3bold',
    'body1link',
    'body2link',
    'body3link',
    'subtitle1',
    'subtitle2',
    'subtitle3',
    'subheading1',
    'subheading2',
    'subheading3',
    'title1',
    'title2',
    'title3',
    'title4',
    'title5',
] as const;
export type TextClassName = (typeof textClassNames)[number];

interface TextProps {
    as?: React.ElementType;
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    textClassName: TextClassName;
}

const Text = ({
    as = 'span',
    children,
    className,
    style,
    textClassName,
}: TextProps) => {
    const Component = as;
    return (
        <Component
            className={clsx($typographyNew[textClassName], className)}
            style={style}
        >
            {children}
        </Component>
    );
};

export { Text };
