import { TouchEvent, useRef } from 'react';

type SwipeOutput = {
    onTouchStart: (e: TouchEvent) => void;
    onTouchMove: (e: TouchEvent) => void;
    onTouchEnd: () => void;
};

type UseSwipeParams = {
    onSwipeLeft?: () => void;
    onSwipeRight?: () => void;
    onSwipeUp?: () => void;
    onSwipeDown?: () => void;
    minSwipeDistance?: number; // the required distance between touchStart and touchEnd to be detected as a swipe
};

export function useSwipe({
    onSwipeLeft,
    onSwipeRight,
    onSwipeUp,
    onSwipeDown,
    minSwipeDistance = 30,
}: UseSwipeParams): SwipeOutput {
    const touchStartX = useRef<number | null>(null);
    const touchEndX = useRef<number | null>(null);
    const touchStartY = useRef<number | null>(null);
    const touchEndY = useRef<number | null>(null);

    const onTouchStart = (e: React.TouchEvent) => {
        touchEndX.current = null;
        touchStartX.current = e.targetTouches[0].clientX;
        touchEndY.current = null;
        touchStartY.current = e.targetTouches[0].clientY;
    };

    const onTouchMove = (e: React.TouchEvent) => {
        touchEndX.current = e.targetTouches[0].clientX;
        touchEndY.current = e.targetTouches[0].clientY;
    };

    const onTouchEnd = () => {
        if (!touchStartX.current || !touchEndX.current) return;

        const distanceX = touchStartX.current - touchEndX.current;
        const distanceY =
            !!touchStartY.current && !!touchEndY.current
                ? touchStartY.current - touchEndY.current
                : 0;
        const isLeftSwipe = distanceX > minSwipeDistance;
        const isRightSwipe = distanceX < -minSwipeDistance;
        const isUpSwipe = distanceY > minSwipeDistance;
        const isDownSwipe = distanceY < -minSwipeDistance;

        if (isRightSwipe && Math.abs(distanceX) > Math.abs(distanceY)) {
            if (onSwipeRight && typeof onSwipeRight === 'function') {
                onSwipeRight();
            }
        }
        if (isLeftSwipe && distanceX > Math.abs(distanceY)) {
            if (onSwipeLeft && typeof onSwipeLeft === 'function') {
                onSwipeLeft();
            }
        }
        if (isUpSwipe && Math.abs(distanceY) > Math.abs(distanceX)) {
            if (onSwipeUp && typeof onSwipeUp === 'function') {
                onSwipeUp();
            }
        }
        if (isDownSwipe && distanceY > Math.abs(distanceX)) {
            if (onSwipeDown && typeof onSwipeDown === 'function') {
                onSwipeDown();
            }
        }
    };

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd,
    };
}
