import { CustomContentProps, SnackbarContent, closeSnackbar } from 'notistack';
import { forwardRef } from 'react';

import { NotificationBlock } from '@components/Notification/block/notificationBlock';

import $toast from '../toast.module.scss';

interface InfoToastProps extends CustomContentProps {
    id: string;
    message: string;
}

const InfoToast = forwardRef<HTMLDivElement, InfoToastProps>((props, ref) => {
    const { id, message } = props;
    return (
        <SnackbarContent
            onClick={() => closeSnackbar(id)}
            ref={ref}
            role="alert"
        >
            <div className={$toast.container}>
                <NotificationBlock type="toast" variant="info">
                    <span>{message}</span>
                </NotificationBlock>
            </div>
        </SnackbarContent>
    );
});

InfoToast.displayName = 'InfoToast';

export { InfoToast };
