import { useFeatureFlagPayload } from 'posthog-js/react';
import { useMemo } from 'react';

import { FLAGS } from '@utilities/constants/featureFlags';
import { ApplicationColor } from '@utilities/styles/getHexValueForApplicationColor';

import { AlertBannerModal } from './types';

const usePosthogAlertBannerModal = (): AlertBannerModal => {
    const payload = useFeatureFlagPayload(FLAGS.ALERT_BANNER.id);

    const bannerMessage = (payload as AlertBannerModal)?.banner?.body;

    const bannerSeenBefore = useMemo(() => {
        if (bannerMessage) {
            const bannerMessageLastSeen = localStorage.getItem(
                FLAGS.ALERT_BANNER.id,
            );
            return bannerMessageLastSeen === bannerMessage;
        }
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(payload as AlertBannerModal)?.banner]);

    const markBannerSeen = () => {
        if (bannerMessage) {
            localStorage.setItem(FLAGS.ALERT_BANNER.id, bannerMessage);
        }
    };

    const alertBannerModalDefaults = {
        banner: {
            enabled: !bannerSeenBefore,
            color: 'salt' as ApplicationColor,
            cta: 'Learn More.',
            showCloseIcon: true,
            onClose: markBannerSeen,
        },
        modal: {
            enabled: !(payload as AlertBannerModal)?.ctaLink, // If there is a ctaLink, we will not show the modal
            cta: 'Got it',
            onClose: markBannerSeen,
        },
    };

    return payload
        ? {
              banner: {
                  ...alertBannerModalDefaults.banner,
                  ...(payload as AlertBannerModal).banner,
              },
              modal: {
                  ...alertBannerModalDefaults.modal,
                  ...(payload as AlertBannerModal).modal,
              },
              ctaLink: (payload as AlertBannerModal).ctaLink,
          }
        : {
              banner: {
                  enabled: false,
              },
              modal: {
                  enabled: false,
              },
          };
};

export { usePosthogAlertBannerModal };
