import clsx from 'clsx';
import { ReactNode } from 'react';

import $notificationBlock from './notificationBlock.module.scss';

type NotificationType = 'toast' | 'block';
type NotificationVariant =
    | 'error'
    | 'info'
    | 'success'
    | 'successLight'
    | 'warning';

interface NotificationBlockProps {
    children: ReactNode;
    type: NotificationType;
    variant: NotificationVariant;
    image?: {
        src: string;
        alt: string;
        height?: number;
        width?: number;
    };
}

const images: Record<
    NotificationType,
    Record<NotificationVariant, string | undefined>
> = {
    toast: {
        error: '/icons/icon-error.svg',
        info: undefined,
        success: '/icons/icon-success.svg',
        successLight: '/icons/icon-success.svg',
        warning: '/icons/icon-warning.svg',
    },
    block: {
        error: '/icons/icon-error-filled.svg',
        info: undefined,
        success: '/icons/icon-success-filled.svg',
        successLight: '/icons/icon-success-filled.svg',
        warning: '/icons/icon-warning.svg',
    },
};

const NotificationBlock = ({
    children,
    image,
    type,
    variant,
}: NotificationBlockProps) => {
    const imgSrc: string | undefined = images[type][variant];

    const doShowImage = image?.src || imgSrc;

    return (
        <div
            className={clsx(
                $notificationBlock.container,
                $notificationBlock[type],
                $notificationBlock[variant],
            )}
        >
            <div className={$notificationBlock.margin}>
                {doShowImage ? (
                    <img
                        src={image?.src || imgSrc}
                        width={image?.width || 20}
                        height={image?.height || 20}
                        alt={`An ${variant} notification`}
                    />
                ) : null}
            </div>
            <div className={$notificationBlock.content}>{children}</div>
            <div className={$notificationBlock.margin} />
        </div>
    );
};

export { NotificationBlock };
