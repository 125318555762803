import { useRouter } from 'next/router';

import { useFeatureFlagVariantKey } from '@custom-hooks/useFeatureFlagVariantKey';
import { FLAGS } from '@utilities/constants/featureFlags';

export function useCostPerUnitDisplayDisabledExperiment() {
    const { query } = useRouter();

    const isCostPerUnitDisplayDisabled = query?.isCostPerUnitDisplayDisabled as
        | string
        | undefined;

    const variant = useFeatureFlagVariantKey(
        FLAGS.DISABLE_COST_PER_UNIT.id,
        FLAGS.DISABLE_COST_PER_UNIT.default,
    );

    let result = false;

    if (isCostPerUnitDisplayDisabled === 'true') {
        result = true;
    } else if (isCostPerUnitDisplayDisabled === 'false') {
        result = false;
    } else if (variant === 'test') {
        result = true;
    }

    return { isCostPerUnitDisplayDisabled: result };
}
