import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { useRefillContext } from '@contexts/RefillsContext';
import {
    DiscountData,
    getDiscountData,
} from '@functions/discount/getDiscountData/getDiscountData';
import { useDashboardContext } from '@scenes/Dashboard/context';
import { PromoCodeDataAction } from '@scenes/Onboarding/types';

const initialState: DiscountData = {
    bannerText: '',
    discountText: '',
    hasSatisfiedCouponMinimum: false,
};

// This hook is used to manage the discount text that is displayed in the dashboard. It determines the discount text
// based on the current order intent total and the promo code data actions, as well as whether the minimum order value
// has been satisfied and the banner text to be shown as a result.
const useDashboardDiscountData = (
    actions: PromoCodeDataAction[] = [],
    allowedTypes?: string[],
): DiscountData => {
    const [result, setResult] = useState<DiscountData>(initialState);
    const [hasBeenUpdatedByUser, setHasBeenUpdatedByUser] = useState(false);

    const { showToast } = useDashboardContext();
    const refills = useRefillContext();

    // Filter the actions based on the allowed types. If there are no allowed types, return
    // all actions.
    const filteredActions = useMemo(
        () =>
            allowedTypes
                ? actions?.filter(
                      (action: PromoCodeDataAction) =>
                          allowedTypes.includes(action.discount_type),
                      actions as PromoCodeDataAction[],
                  )
                : actions,
        [actions, allowedTypes],
    );

    const currentOrderIntentTotal =
        refills.state === 'complete' ? refills.now?.subtotal : 0;

    useEffect(() => {
        if (refills && refills.state === 'complete') {
            // Get the new result based on the filtered actions and the current order intent total.
            const newResult = getDiscountData(
                filteredActions,
                currentOrderIntentTotal,
            );

            // If the hasBeenUpdatedByUser flag is true and the minimum order value has been satisfied,
            // show the toast with the banner text.
            if (
                hasBeenUpdatedByUser &&
                !result.hasSatisfiedCouponMinimum &&
                newResult.hasSatisfiedCouponMinimum
            ) {
                showToast(newResult.bannerText, {
                    variant: 'success',
                });
            }
            // If the new result is different from the current result, update the state, and check
            // if the discount text has been initialized and has changed. If it has, set the
            // hasBeenUpdatedByUser flag to true, allowing the toast to display after initialization.
            if (!isEqual(newResult, result)) {
                if (
                    newResult.discountText &&
                    result.discountText &&
                    newResult.discountText !== result.discountText
                ) {
                    setHasBeenUpdatedByUser(true);
                }
                setResult(newResult);
            }
        }
    }, [
        currentOrderIntentTotal,
        filteredActions,
        hasBeenUpdatedByUser,
        refills,
        result,
        showToast,
    ]);

    return result;
};

export { useDashboardDiscountData };
