import { MobileHeaderSubComponentName } from '@scenes/Dashboard/components/mobileHeader/mobileHeader.types';

/**
 * This function returns an array of MobileHeaderSubComponentNames based on the destination page
 * This allows the mobile header to be customized for different pages. The sub-components will be rendered
 * based on the array of the names returned by this function in the order they appear in their respective list.
 * */
const getMobileHeaderSubComponentsForPage = (page: string) => {
    switch (page) {
        case '/search':
            return [
                MobileHeaderSubComponentName.Base,
                MobileHeaderSubComponentName.SearchResults,
                MobileHeaderSubComponentName.Search,
            ];
        // All other pages
        default:
            return [
                MobileHeaderSubComponentName.Base,
                MobileHeaderSubComponentName.Search,
            ];
    }
};

export { getMobileHeaderSubComponentsForPage };

/**
 * This function returns the index of the sub-component that should be below the fold when the mobile header
 * is collapsed based on the current page. This allows the mobile header to be customized for different pages.
 *
 * For example, if the mobile header has three components and only the first subcomponent should be above the fold when
 * the header is collapsed, this function should return 1. If we want the second subcomponent to be above the fold, this
 * function should return 2. If we want the all three subcomponents to be below the fold (hiding the entire header) this
 * function should return 3.
 *
 * */
const getMobileHeaderSubComponentsCollapseToIndexForPage = (page: string) => {
    switch (page) {
        case '/search':
            return 2;
        // All other pages
        default:
            return 1;
    }
};

export { getMobileHeaderSubComponentsCollapseToIndexForPage };
