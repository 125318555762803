interface ProductRemovalActionClickedArguments {
    sellableEntityVariationId: number;
    action: 'cancel' | 'remove from autopilot' | 'remove from refill';
    orderIntentId?: number;
}
export const trackProductRemovalActionClicked = ({
    sellableEntityVariationId,
    action,
    orderIntentId,
}: ProductRemovalActionClickedArguments) => {
    window?.analytics?.track('Product Removal Action Clicked', {
        sellable_entity_variation_id: sellableEntityVariationId,
        action,
        order_intent_id: orderIntentId,
    });
};
