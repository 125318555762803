import clsx from 'clsx';

import $nakedButton from './nakedButton.module.scss';

export function NakedButton({
    className,
    type = 'button',
    ...restProps
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <button
            className={clsx($nakedButton.nakedButton, className)}
            // eslint-disable-next-line react/button-has-type
            type={type}
            {...restProps}
        />
    );
}
