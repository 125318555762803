import { Prediction, Product } from '@scenes/Onboarding/types';

const mapProductsToGAProducts = (products: Product[]) =>
    // TODO: I only included required fields to start, to keep mapping simpler and roll this out ASAP. There are many more fields that can be added, see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#implementation for the spec
    products.map((product) => ({
        item_id: product.id,
        item_name: product.name,
        price: product.price,
        quantity: (product?.prediction as Prediction)?.quantity || 1,
    }));

const mapProductsToGACartEcommerceObject = (products: Product[]) => ({
    currency: 'USD',
    value: products.reduce(
        (acc: number, product: Product) => acc + product.price,
        0,
    ),
    items: mapProductsToGAProducts(products),
    transaction_id: '',
});

export { mapProductsToGACartEcommerceObject };
