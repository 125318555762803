import { ErrorService } from '@services/error';

export const identifyCity = (cityId: number) => {
    let anonymousId;
    try {
        anonymousId = window.analytics.user().anonymousId().toString();
    } catch (error) {
        ErrorService.captureError(
            new Error(
                `Error accessing window.analytics.user(): ${JSON.stringify(
                    error,
                )}`,
            ),
        );
    }
    window?.analytics?.identify(
        {
            city_id: cityId,
        },
        {
            anonymousId,
        },
    );
};
