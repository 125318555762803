import { CustomContentProps, SnackbarContent, closeSnackbar } from 'notistack';
import { forwardRef } from 'react';

import { NotificationBlock } from '@components/Notification/block/notificationBlock';

import $toast from '../toast.module.scss';

interface ErrorToastProps extends CustomContentProps {
    id: string;
    message: string;
}

const ErrorToast = forwardRef<HTMLDivElement, ErrorToastProps>((props, ref) => {
    const { id, message } = props;

    return (
        <SnackbarContent
            onClick={() => closeSnackbar(id)}
            ref={ref}
            role="alert"
        >
            <div className={$toast.container}>
                <NotificationBlock type="toast" variant="error">
                    <span>{message}</span>
                </NotificationBlock>
            </div>
        </SnackbarContent>
    );
});

ErrorToast.displayName = 'ErrorToast';

export { ErrorToast };
