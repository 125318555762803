import { useApolloClient } from '@apollo/client';
import React, { useContext, useEffect } from 'react';

import { useAuthContext } from '@contexts/AuthContext';
import { GET_CATEGORY_TREE_V2_BY_SLUG } from '@custom-queries/dashboard';
import { CategoryV2 } from '@gql/graphql';
import { MAIN_MENU_SLUG } from '@utilities/common';

interface UserDashboardContextProps {
    categoriesMenu: CategoryV2 | null;
}

const UserDashboardContext = React.createContext(
    {} as UserDashboardContextProps,
);

const useUserDashboardContext = () => useContext(UserDashboardContext);

function UserDashboardProvider({ children = null }) {
    const { isLoggedIn } = useAuthContext();
    const [categoriesMenu, setCategoriesMenu] =
        React.useState<CategoryV2 | null>(null);

    const client = useApolloClient();

    const getCategoryMenu = async () => {
        const { data } = await client.query({
            query: GET_CATEGORY_TREE_V2_BY_SLUG,
            variables: {
                slug: MAIN_MENU_SLUG,
            },
            fetchPolicy: 'cache-first',
        });
        if (data) setCategoriesMenu(data.getCategoryV2BySlug);
    };

    useEffect(() => {
        if (!categoriesMenu && isLoggedIn) {
            getCategoryMenu();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    return (
        <UserDashboardContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                categoriesMenu,
            }}
        >
            {children}
        </UserDashboardContext.Provider>
    );
}

export { UserDashboardProvider, useUserDashboardContext };
