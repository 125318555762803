import { DaysOfWeek } from '@mlkmncode/common';

import { TrackId } from '@scenes/Onboarding/types';

export const identifyOnboardingCompleted = (
    userId: string,
    email: string | undefined,
    phoneNumber: string | undefined,
    refillDay: DaysOfWeek | null | undefined,
    onboardingTrack: TrackId,
) => {
    if (window && window.analytics) {
        window.analytics.identify(userId, {
            email,
            is_lead: false, // Once onboarding is completed, User is no longer a lead
            phone_number: phoneNumber,
            phoneNumber,
            refill_day: refillDay,
            onboarding_track: onboardingTrack,
            onboardingTrack,
        });
    }
};
