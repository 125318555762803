import { Product } from '@scenes/Onboarding/types';

/**
 * Takes in a list of products from onboarding context and return the sum of all products' prices
 */
const sumProducts = (products: Product[]) => {
    if (products) {
        return products.reduce(
            (acc, product) => acc + Number(product.price),
            0,
        );
    }
    return 0;
};

export { sumProducts };
