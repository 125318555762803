import clsx from 'clsx';
import {
    type CSSProperties,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { NakedButton } from '@components-design-system/button';
import { Text } from '@components-design-system/text';
import { useCueContext } from '@contexts/CueContext';
import { CueName, CueOffset, CueStatus } from '@type/cues';

import $cue from './Cue.module.scss';
import { CARET_SIZE } from './constants';
import {
    computeStyles,
    mapCueDisplayStyle,
    trackDismissedCue,
    trackViewedCue,
} from './utils';

type CueConfig = {
    offset?: CueOffset;
};

type CueProps = {
    name: CueName;
    copy: string;
} & (
    | {
          position: 'static';
      }
    | {
          position: 'anchor';
          anchorSelector: string; // DOM selector to anchor the tooltip against
      }
);

type CueStaticProps = {
    name: CueName;
    status: CueStatus;
    copy: string;
    onDismiss: () => void;
};

type CueAnchorProps = {
    name: CueName;
    status: CueStatus;
    copy: string;
    anchorSelector: string;
    onDismiss: () => void;
};

const CUES_MAP: Record<CueName, CueConfig> = {
    [CueName.FirstPQVOpen]: { offset: { bottom: 16 } },
    [CueName.PLPProductFrequency]: {},
    [CueName.FirstOneTimeAdd]: { offset: { top: 4 } },
    [CueName.FirstAutopilotAdd]: { offset: { top: 4 } },
};

function CueContent(
    props: Pick<CueAnchorProps | CueStaticProps, 'copy' | 'onDismiss'>,
) {
    return (
        <>
            <Text as="p" textClassName="body3medium">
                {props.copy}
            </Text>

            <NakedButton className={$cue.button} onClick={props.onDismiss}>
                <Text
                    as="span"
                    textClassName="body3medium"
                    className={$cue.buttonLabel}
                >
                    Got it
                </Text>
            </NakedButton>
        </>
    );
}

function CueAnchor(props: CueAnchorProps) {
    const targetRef = useRef<HTMLDivElement>(null);
    const [styles, setStyles] = useState<CSSProperties>({ display: 'none' });

    useEffect(() => {
        const observer = new MutationObserver(() => {
            const cue = CUES_MAP[props.name];
            const el = document.querySelector(props.anchorSelector);

            if (el) {
                const [newStyles, caretOffset] = computeStyles(
                    el,
                    targetRef.current,
                    cue.offset,
                );

                setStyles(newStyles);

                // NOTE: This is used in CSS to position the ::before pseudo element
                targetRef.current?.style.setProperty(
                    '--caret-offset',
                    `${caretOffset}px`,
                );

                // NOTE: This is used in CSS to size the caret
                targetRef.current?.style.setProperty(
                    '--caret-size',
                    `${CARET_SIZE}px`,
                );
            }
        });

        if (props.status === 'visible') {
            observer.observe(document.body, {
                attributes: false,
                subtree: true,
                childList: true,
            });
        }

        return () => {
            observer.disconnect();
        };
    }, [props.name, props.anchorSelector, props.status]);

    return (
        <div
            ref={targetRef}
            className={clsx($cue.container, $cue.containerAnchor)}
            style={{
                display: mapCueDisplayStyle(props.status),
                ...styles,
            }}
        >
            <CueContent copy={props.copy} onDismiss={props.onDismiss} />
        </div>
    );
}

function CueStatic(props: CueStaticProps) {
    const cue = CUES_MAP[props.name];

    return (
        <div
            className={clsx($cue.container, $cue.containerStatic)}
            style={{
                display: mapCueDisplayStyle(props.status),
                marginTop: cue.offset?.top,
                marginBottom: cue.offset?.bottom,
            }}
        >
            <CueContent copy={props.copy} onDismiss={props.onDismiss} />
        </div>
    );
}

export function Cue(props: CueProps) {
    const { show, dismiss, cues } = useCueContext();
    const cueStatus = cues[props.name];

    const onDismiss = useCallback(() => {
        dismiss(props.name);
        trackDismissedCue(props.name);
    }, [props.name, dismiss]);

    // Analytics viewed event
    useEffect(() => {
        if (cueStatus === 'visible') {
            trackViewedCue(props.name);
        }
    }, [cueStatus, props.name]);

    // Auto-show when static
    useEffect(() => {
        if (props.position === 'static') {
            show(props.name);
        }
    }, [props.name, props.position, show]);

    return props.position === 'anchor' ? (
        <CueAnchor
            name={props.name}
            status={cueStatus}
            copy={props.copy}
            anchorSelector={props.anchorSelector}
            onDismiss={onDismiss}
        />
    ) : (
        <CueStatic
            name={props.name}
            status={cueStatus}
            copy={props.copy}
            onDismiss={onDismiss}
        />
    );
}
