import { MovPromo } from '@custom-hooks/featureFlag/useTieredMovPromo';
import { ErrorService } from '@services/error';
import { formatMoney } from '@utilities/money';

type TieredMovPromoBannerCopy = {
    message: string; // can include dangerouslySetHTML
    cta?: string;
    ctaLink?: string;
};

function getTieredMovPromoBannerCopy(
    promos: MovPromo[],
    nowBundleTotal: number | bigint,
    minimumOffsetFromLowestTier = 10,
    hasSkipped = false,
): TieredMovPromoBannerCopy {
    // Ensures Posthog setup lists tiers in order
    const sortedPromos = promos.sort((a, b) => a.orderValue - b.orderValue);

    const allTiersCopy = sortedPromos.map(
        ({ discount, orderValue }) =>
            ` ${formatMoney(discount, {
                stripTrailingZeros: true,
            })} off ${formatMoney(orderValue, {
                stripTrailingZeros: true,
            })}`,
    );

    // If they've skipped, show default messaging nudging to unskip
    if (hasSkipped) {
        return {
            message: `THIS WEEK ONLY: Unskip to get${allTiersCopy}`,
            cta: 'Unskip Now',
            ctaLink: '/dashboard/grid',
        };
    }

    // If well below the first tier, show default messaging (mention all tiers)
    if (
        nowBundleTotal <
        sortedPromos[0].orderValue - minimumOffsetFromLowestTier
    ) {
        return {
            message: `THIS WEEK ONLY: Get${allTiersCopy}`,
            cta: 'Shop Now',
            ctaLink: '/discover',
        };
    }

    // Find the next promo level based on current Now subtotal
    const nextPromoLevel = sortedPromos.find(
        (promo) => promo.orderValue > nowBundleTotal,
    );

    // If above the highest tier, show the overall discount
    const highestTier = sortedPromos[sortedPromos.length - 1];
    if (!nextPromoLevel) {
        return {
            message: `Woohoo! You're getting ${formatMoney(
                highestTier.discount,
                {
                    stripTrailingZeros: true,
                },
            )} off your refill this week ⭐️`,
        };
    }

    // Otherwise show dynamic messaging: amount needed until next tier is reached
    const amountUntilNextTier =
        nextPromoLevel.orderValue - Number(nowBundleTotal);

    let formattedAmountUntilNextTier: number | string = amountUntilNextTier;
    let formattedDiscountAmount: number | string = nextPromoLevel.discount;
    try {
        formattedAmountUntilNextTier = formatMoney(amountUntilNextTier, {
            stripTrailingZeros: true,
        });
        formattedDiscountAmount = formatMoney(nextPromoLevel.discount, {
            stripTrailingZeros: true,
        });
    } catch (e) {
        ErrorService.captureError(e as Error, {
            amountUntilNextTier,
            nextPromoLevel,
            nowBundleTotal,
        });
    }

    return {
        message: `<span>You're <b>${formattedAmountUntilNextTier}</b> away from getting <b>${formattedDiscountAmount} off</b> your bundle!</span>`,
        cta: 'Add More',
        ctaLink: '/dashboard/inventory/new-products',
    };
}

export { getTieredMovPromoBannerCopy };
