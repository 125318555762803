import { graphql } from '@gql/gql';

export const DISCOVER_HOME_QUERY = graphql(`
    query DiscoverHome {
        discover {
            sections {
                ... on DiscoverSectionProduct {
                    slug
                    title
                    description
                    action {
                        url
                        buttonLabel
                    }
                    items {
                        product {
                            ...ProductCardParts
                        }
                    }
                }

                ... on DiscoverSectionBanner {
                    slug
                    title
                    description
                    action {
                        url
                        buttonLabel
                    }
                    style {
                        variant
                        backgroundColor
                    }
                    items {
                        id
                        name
                        description
                        imageUrl
                        action {
                            url
                            buttonLabel
                        }
                        style {
                            variant
                            backgroundColor
                        }
                    }
                }
            }
        }
    }
`);
