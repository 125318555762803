import { TrackId, UTM } from '@scenes/Onboarding/types';

export const trackPersonalDetailsPageSubmit = (
    onboardingTrack: TrackId,
    utm: UTM | undefined,
) => {
    window?.analytics?.track(
        'Onboarding Personal Details Page Submitted',
        {
            onboardingTrack,
        },
        utm,
    );
};
