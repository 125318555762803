import { Product, TrackId } from '@scenes/Onboarding/types';

import { mapProductsToGACartEcommerceObject } from '../../../../scenes/Onboarding/functions/product/mapProductsToGACartEcommerceObject/mapProductsToGACartEcommerceObject';

// Custom event
export const GoogleAnalyticsAccountInfoSubmitted = (
    onboardingTrack: TrackId,
    products: Product[] | undefined,
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window && window.dataLayer && window.dataLayer.push({ ecommerce: null });
    const event = {
        event: 'account_information_submitted',
        onboardingTrack,
        ecommerce: mapProductsToGACartEcommerceObject(products || []),
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window && window.dataLayer && window.dataLayer.push(event);
};
