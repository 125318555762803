import clsx from 'clsx';
import { CustomContentProps, SnackbarContent, closeSnackbar } from 'notistack';
import { forwardRef } from 'react';

import { NakedButton } from '@components-design-system/button';
import { useSwipe } from '@custom-hooks/useSwipe';

import $styles from './ToastNotificationWithCTA.module.scss';

interface ToastNotificationWithCTAProps extends CustomContentProps {
    ctaText?: string;
    ctaOnClick?: () => void;
}

const ToastNotificationWithCTA = forwardRef<
    HTMLDivElement,
    ToastNotificationWithCTAProps
>((props, ref) => {
    const { message, ctaText, ctaOnClick } = props;
    const showCTA = !!ctaText && !!ctaOnClick;
    const swipe = useSwipe({
        onSwipeUp: () => {
            closeSnackbar();
        },
        onSwipeLeft: () => {
            closeSnackbar();
        },
        onSwipeRight: () => {
            closeSnackbar();
        },
    });
    return (
        <SnackbarContent
            ref={ref}
            role="alert"
            onTouchStart={swipe.onTouchStart}
            onTouchMove={swipe.onTouchMove}
            onTouchEnd={swipe.onTouchEnd}
        >
            <div
                className={clsx(
                    $styles.notification,
                    $styles.notification__variant_info,
                )}
            >
                <div className={clsx($styles.notification__body)}>
                    <span>{message}</span>
                    {showCTA && (
                        <NakedButton
                            onClick={() => {
                                closeSnackbar();
                                ctaOnClick();
                            }}
                        >
                            {ctaText}
                        </NakedButton>
                    )}
                </div>
            </div>
        </SnackbarContent>
    );
});

ToastNotificationWithCTA.displayName = 'ToastNotificationWithCTA';

export { ToastNotificationWithCTA };
