import { USD_CURRENCY } from '@scenes/Onboarding/constants/analyticsConstants';
import { sumProducts } from '@scenes/Onboarding/functions/product/sumProducts/sumProducts';
import { Product, PromoCodeData, TrackId, UTM } from '@scenes/Onboarding/types';

// This is a segment standard event, be careful changing!

export const SegmentFinishOnboarding = (
    userId: number,
    email: string | undefined,
    refillProducts: Product[],
    onboardingTrack: TrackId,
    paymentMethod: 'card' | 'apple' | 'google',
    referral: PromoCodeData | null | undefined,
    utm: UTM | undefined,
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window?.analytics?.track(
        'Order Completed',
        {
            userId,
            email,
            subtotal: sumProducts(refillProducts),
            currency: USD_CURRENCY,
            onboardingTrack,
            payment_method: paymentMethod,
            products: refillProducts.map((product) => ({
                product_id: product.id,
                name: product.name,
                price: product.price,
                quantity: 1, // hardcoding 1 for now as most products will get that as default, and the way we do predictions needs to change anyways
            })),
            referral,
        },
        utm,
    );
};
