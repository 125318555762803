import { TrackId, UTM } from '@scenes/Onboarding/types';

type TrackZipPageSubmitProps = {
    // required attributes
    onboardingTrack: TrackId;
    zipCode: string;
    isDeliveryAvailable: boolean;

    // optional attributes
    cityId: number | undefined;
    utm: UTM | undefined;
};

export const trackZipPageSubmit = ({
    isDeliveryAvailable,
    onboardingTrack,
    zipCode,
    cityId,
    utm,
}: TrackZipPageSubmitProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window?.analytics?.track(
        'Onboarding Zip Page Submitted',
        {
            onboardingTrack,
            isDeliveryAvailable,
            zipCode,
            cityId,
            city_id: cityId,
        },
        utm,
    );
};
