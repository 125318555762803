import { noop } from 'lodash';
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { LOCAL_STORAGE } from '@constants/localStorageContants';
import { CueName, CueStatus } from '@type/cues';

type ICueContext = {
    show: (name: CueName) => void;
    dismiss: (name: CueName) => void;
    cues: Record<CueName, CueStatus>;
};

const initialCueContext: ICueContext = {
    show: noop,
    dismiss: noop,
    cues: Object.values(CueName).reduce(
        (acc, key) => ({ ...acc, [key]: 'indeterminate' }),
        {} as ICueContext['cues'],
    ),
};

const CueContext = createContext<ICueContext>(initialCueContext);

const useCueContext = () => useContext(CueContext);

function getLocalStorageCues() {
    try {
        const item = localStorage.getItem(LOCAL_STORAGE.APPLICATION_CUES);

        let data: Partial<ICueContext['cues']> = {};
        if (item) {
            data = JSON.parse(item);
        }

        return data;
    } catch (error) {
        console.error(error);
        return {};
    }
}

const CuesProvider = ({ children }: { children: ReactNode }) => {
    const [cues, setCues] = useState<ICueContext['cues']>(
        initialCueContext.cues,
    );

    useEffect(() => {
        const data = getLocalStorageCues();

        const newState = Object.values(CueName).reduce((acc, key) => {
            const datum = data[key];
            const hasSeenCue = typeof datum === 'string' && datum.length > 0; // todo: validate date

            return { ...acc, [key]: hasSeenCue ? 'seen' : 'unseen' };
        }, {});

        setCues((state) => ({ ...state, ...newState }));
    }, []);

    const show = useCallback((name: CueName) => {
        setCues((state) => ({
            ...state,
            [name]: state[name] === 'unseen' ? 'visible' : state[name],
        }));
    }, []);

    const dismiss = useCallback((name: CueName) => {
        const data = getLocalStorageCues();

        try {
            if (!data[name]) {
                const visitedAt = new Date().toISOString();

                localStorage.setItem(
                    LOCAL_STORAGE.APPLICATION_CUES,
                    JSON.stringify({
                        ...data,
                        [name]: visitedAt,
                    }),
                );

                setCues((state) => ({ ...state, [name]: 'seen' }));
            }
        } catch (error) {
            console.error('Failed to dismiss cue', error);
        }
    }, []);

    const contextProviderValue: ICueContext = useMemo(
        () => ({ show, dismiss, cues }),
        [show, dismiss, cues],
    );

    return (
        <CueContext.Provider value={contextProviderValue}>
            {children}
        </CueContext.Provider>
    );
};

export { CuesProvider, useCueContext };
