import { TrackId } from '@scenes/Onboarding/types';

export const trackOneClickButtonInteraction = (
    paymentMethod: string,
    trackId: TrackId,
) => {
    window?.analytics?.track('Clicked One Click Payment', {
        onboardingTrack: trackId,
        payment_method: paymentMethod,
    });
};
