import { gql } from '@apollo/client';

import { graphql } from '@gql/gql';

export const SEARCH_PRODUCTS = graphql(`
    query searchProductsV2($query: String!, $brands: [String!]) {
        searchProductsV2(
            input: {
                query: { searchTerm: $query, filter: { brands: $brands } }
            }
        ) {
            traceId
            results {
                brand
                createdAt
                default_frequency
                default_add_behavior
                description
                description_short
                id
                images {
                    xs
                    sm
                    md
                    lg
                }
                alternative_images {
                    images {
                        xs
                        sm
                        md
                        lg
                    }
                }
                ingredients
                in_bundle
                name
                number_of_bundles_containing
                packaging_sustainability_info
                price
                primary_category {
                    parent_category {
                        slug
                    }
                }
                query_id
                retail_price
                refill_settings {
                    frequency {
                        value
                        origin
                    }
                }
                sellable_count
                sellable_qty
                sellable_unit
                subscription_price
            }
        }
    }
`);

export const SEARCH_RECOMMENDED_TERM = gql`
    query searchTermSuggestions($searchTerm: String!) {
        searchTermSuggestions(searchTerm: $searchTerm) {
            searchSuggestionList
            queryId
        }
    }
`;

export const GET_PRODUCTS_AUTOCOMPLETE = gql`
    query getProductsAutocomplete($query: String!, $max_result_count: Int) {
        getProductsAutocomplete(
            query: $query
            max_result_count: $max_result_count
        ) {
            id
            in_bundle
            name
            brand
            price
            description
            ingredients
            images {
                xs
                sm
                md
                lg
            }
            sellable_qty
            sellable_unit
        }
    }
`;

export const GET_CATEGORY_TREE_V2_BY_SLUG = gql`
    query getCategoryTreeV2BySlug($slug: String!) {
        getCategoryV2BySlug(slug: $slug) {
            id
            slug
            order
            subcategories {
                id
                slug
                order
                display_name
                product_count
                parent_category {
                    id
                    slug
                    display_name
                    alternate_display_name
                }
                subcategories {
                    id
                    slug
                    order
                    display_name
                    alternate_display_name
                    product_count
                    parent_category {
                        id
                        slug
                        display_name
                        alternate_display_name
                    }
                }
            }
        }
    }
`;

export const GET_RECOMMENDATIONS_BY_SLUG = gql`
    query getPersonalizedProductRecommendations($slug: String!) {
        getPersonalizedProductRecommendations(slug: $slug) {
            id
            name
            description
            description_short
            default_frequency
            default_add_behavior
            ingredients
            brand
            images {
                xs
                sm
                md
                lg
            }
            refill_settings {
                frequency {
                    value
                    origin
                }
            }
            service_type
            sellable_qty
            sellable_unit
            sellable_count
            price
            retail_price
            subscription_price
            createdAt
            in_bundle
            number_of_bundles_containing
            is_local
            query_id
            child_products {
                id
                name
            }
        }
    }
`;

export const GET_CATEGORY_TREE_V2_BY_SLUG_NO_PRODUCT_COUNT = gql`
    query getCategoryTreeV2BySlugNoProductCount($slug: String!) {
        getCategoryV2BySlug(slug: $slug) {
            id
            slug
            subcategories {
                id
                slug
                display_name
                subcategories {
                    id
                    slug
                    display_name
                }
            }
        }
    }
`;
