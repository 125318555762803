import { ClickOrigin } from '../constants';

type ProductListViewedParams = {
    category: ClickOrigin;
    listId?: string;
    products: number[];
};
type ProductListViewedFunction = (params: ProductListViewedParams) => void;

/**
 * Track event when a member views a product list.
 * @param category - The category of the product list - e.g. 'collection', 'beverages'
 * @param listId - The ID of the product list - e.g. 'spring-collection'
 * @param products - The IDs of the products in the list - e.g. [123, 456, 789]
 * @constructor
 */
const ProductListViewed: ProductListViewedFunction = ({
    category,
    listId = undefined,
    products,
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Product List Viewed', {
            category,
            list_id: listId,
            products,
        });
};

export { ProductListViewed };
