import { CustomContentProps, SnackbarContent, closeSnackbar } from 'notistack';
import { forwardRef } from 'react';

import { NotificationBlock } from '@components/Notification/block/notificationBlock';

import $toast from '../toast.module.scss';

interface SuccessToastProps extends CustomContentProps {
    id: string;
    message: string;
}

const SuccessToast = forwardRef<HTMLDivElement, SuccessToastProps>(
    (props, ref) => {
        const { id, message } = props;

        return (
            <SnackbarContent
                onClick={() => closeSnackbar(id)}
                ref={ref}
                role="alert"
            >
                <div className={$toast.container}>
                    <NotificationBlock type="toast" variant="success">
                        <span>{message}</span>
                    </NotificationBlock>
                </div>
            </SnackbarContent>
        );
    },
);

SuccessToast.displayName = 'SuccessToast';

export { SuccessToast };
