import { useMemo } from 'react';

import { useAuthContext } from '@contexts/AuthContext';
import { useDashboardDiscountData } from '@custom-hooks/discount/useDashboardDiscountData';
import { useDashboardContext } from '@scenes/Dashboard/context';
import { formatMoney } from '@utilities/money';

import { AlertBannerModal } from './types';

// We only display the following types of coupons in the banner.
const VALID_COUPON_TYPES = ['order_flat', 'order_percent'];

export const useUserAvailableCreditBanner = (): AlertBannerModal => {
    const { userAccountInfo } = useAuthContext();
    const { userActiveCoupon } = useDashboardContext();

    const { discountText, hasSatisfiedCouponMinimum } =
        useDashboardDiscountData(
            userActiveCoupon?.coupon?.actions,
            VALID_COUPON_TYPES,
        );

    const availableCredit = useMemo(() => {
        let creditAmount = null;
        if (userAccountInfo?.getUserProfile?.stripe_credit?.credit_amount) {
            creditAmount =
                (
                    userAccountInfo.getUserProfile.stripe_credit.credit_amount *
                    -1
                ).toFixed(2) || null;
        }
        return {
            data: {
                amount: creditAmount,
                userActiveCoupon,
            },
            loading: false,
            error: null,
        };
    }, [userAccountInfo, userActiveCoupon]);

    const availableCreditRequestSuccess =
        availableCredit.loading === false && !availableCredit.error;

    let enabled = false;
    if (
        availableCreditRequestSuccess &&
        (availableCredit.data.amount !== null || discountText !== null)
    ) {
        enabled = true;
    }
    // If the Member has satisfied the minimum order value for the discount, disable the banner.
    if (hasSatisfiedCouponMinimum) {
        enabled = false;
    }

    const body = useMemo(() => {
        if (!enabled) {
            return undefined;
        }
        if (availableCredit.data.amount) {
            return `<b>You have ${formatMoney(
                parseInt(availableCredit.data.amount, 10),
                { stripTrailingZeros: true },
            )} in account credit. This will be applied to your next order.</b>`;
        }
        if (discountText) {
            return `<b>${discountText}</b>`;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        availableCredit.loading,
        availableCredit.error,
        availableCredit.data.amount,
        discountText,
        hasSatisfiedCouponMinimum,
    ]);
    return {
        banner: {
            body,
            color: 'matcha',
            enabled,
            mobileAlignment: 'center',
        },
        modal: {
            enabled: false,
        },
    };
};
