interface TrackOnboardingWaitlistRequestArgs {
    firstName: string;
    lastName: string;
    email: string;
    zipCode: string;
    phoneNumber?: string;
    autoOptIn?: boolean;
}

export const trackOnboardingWaitlistRequest = async ({
    email,
    firstName,
    lastName,
    zipCode,
    phoneNumber,
    autoOptIn,
}: TrackOnboardingWaitlistRequestArgs) => {
    window?.analytics?.track('Joined Waitlist', {
        email,
        firstName,
        lastName,
        zipCode,
        phoneNumber,
        autoOptIn: autoOptIn ?? false,
    });
};
