import { DateTime } from 'luxon';

export function nowDate(): string {
    return DateTime.now().toISODate() as string;
}

export function getOrdinalForDay(day: number): string {
    const pr = new Intl.PluralRules('en-US', {
        type: 'ordinal',
    });
    const suffixes = new Map([
        ['one', 'st'],
        ['two', 'nd'],
        ['few', 'rd'],
        ['other', 'th'],
    ]);
    const rule = pr.select(day);
    return suffixes.get(rule) || '';
}
