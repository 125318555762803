import { useFeatureFlagVariantKey } from '@custom-hooks/useFeatureFlagVariantKey';
import { FLAGS } from '@utilities/constants/featureFlags';

const useNewSortOrder = () => {
    const variationKey = useFeatureFlagVariantKey(
        FLAGS.NEW_SORT_ORDER.id,
        FLAGS.NEW_SORT_ORDER.default,
    );
    return {
        shouldUseNewSortOrder: variationKey === 'test',
    };
};

export { useNewSortOrder };
