const LOCAL_STORAGE = {
    APPLICATION_NOTIFICATIONS: 'applicationNotifications',
    APPLICATION_CUES: 'applicationCues',
    DASHBOARD_SAVED_INVENTORY_PRODUCTS: 'dashboardSavedInventoryProducts',
    SHOW_REFILL_ONBOARDING_PROMPT: 'showRefillOnboardingPrompt',
    IS_NOTIFIED_CREDIT_APPLIED: 'isNotifiedCreditApplied',
    ONBOARDING_SAVED_USER_KEY: 'onboardingSavedUser',
    PREFERENCES_QUIZ_LAST_SEEN: 'preferencesQuizLastSeen',
};

export { LOCAL_STORAGE };
