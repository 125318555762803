import { DateTime } from 'luxon';

import { formatMoney } from './money';

// TODO: Clean up anything that is not used.
export const ALPHABETS_ONLY = '^[A-Za-z]+$';
export const ALPHABETS_ONLY_WITH_SPACE = '^([A-Za-z]+ ?)*$';
export const ALPHABETS_NUMBER_ONLY = '^[a-zA-Z0-9_.-]*$';
export const ALPHABETS_NUMBER_SPECIAL_CHARACTERS = '^[ A-Za-z0-9_@#&]*$';
export const ADDRESS_PATTERN = '^([ A-Za-z0-9-/\\,#@]+ ?)*$';
export const PASSWORD_PATTERN =
    /(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{8,20})$/;
export const NO_SPACE_PATTERN = /^\S*$/;
export const EMAIL_PATTERN =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MOBILE_PATTERN = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const ONLY_ONE_NUMBER_PATTERN = /^[0-9]{1,1}$/;
export const ONLY_NUMBER_PATTERN = /^\d+$/;
export const ONLY_POSITIVE_NUMBER_DECIMAL_PATTERN =
    /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/;
export const ONLY_ALPHABETS_PATTERN = /^[A-Za-z]+$/;
export const ONLY_POSITIVE_NUMBER_PATTERN = '^[0-9]*$';
export const DEFAULT_ERROR_MESSAGE =
    'An unknown error occurred. Please try again later.';
export const ERROR_MESSAGE_RESETPASSWORD =
    'This password link is invalid. Please request another and try again. If you still have issues, email support@therounds.co';
export const AUTO_HIDE_DURATION_FOR_SNACKBAR = 6000;
export const SEVERITY_FOR_SNACKBAR = 'error'; // severity
export const DEFAULT_ERROR_MESSAGE_FOR_PRODUCTS_NOT_SELECTED =
    'Please select the products by changing the quantity';
export const DEFAULT_SUCCESS_MESSAGE_ON_ADD = 'added successfully';
export const DEFAULT_SUCCESS_MESSAGE_ON_GET = 'fetched successfully';
export const DEFAULT_SUCCESS_MESSAGE_ON_UPDATE = 'updated successfully';
export const DEFAULT_SUCCESS_MESSAGE_ON_DELETE = 'deleted successfully';
export const SET_INTERVAL_TIMEOUT = 3000;
export const GOOGLE_TAG_MANAGER_ID = 'GTM-WVZKR97';
export const SUPPRT_SMS_NUMBER = '(917) 810-3182';
export const MEMBERSHIP_PRICE = 10;
export const MAIN_MENU_SLUG = 'main-menu';

export const getValue = (obj, expression) => {
    try {
        return expression.split('.').reduce((o, x, i) => {
            if (i === 0) {
                return o;
            }
            return typeof o === 'undefined' || o === null ? undefined : o[x];
        }, obj);
    } catch (e) {
        console.error(`getValue => ${e}`);
        return undefined;
    }
};

export const isEmpty = (value) =>
    !value ||
    value === null ||
    (typeof value === 'string' && value.trim() === '');

export const isNumeric = (value) => {
    if (!value) return true;
    // eslint-disable-next-line prefer-regex-literals
    const reg = new RegExp('^[0-9]+$');
    return reg.test(value);
};

export const isValueValid = (value, regExp) => {
    const reg = new RegExp(regExp);
    return reg.test(value);
};

export const isValueMatch = (value, matchValue) => value === matchValue;
export const isLessThanMinLength = (value, length) => value.length <= length;

export const isGreaterThanMaxLength = (value, length) => value.length >= length;

export function showDataForAmount(data) {
    const amount = data || 0;
    const amountToDisplay = `$${amount.toFixed(2)}`;
    return amountToDisplay;
}

export function showDataForString(data) {
    const dataToDisplay = data || 'N/A';
    return dataToDisplay;
}

export function showData(data) {
    const defaultMessage =
        typeof data !== 'number' ? 'No Data' : parseInt(0, 10);
    return data || defaultMessage;
}

export const getDeliveryFrequencyGrouping = () => {
    const startingMonth = 1;
    const endMonth = 12;
    const deliveryFrequency = {};
    // eslint-disable-next-line no-plusplus
    for (let i = startingMonth; i <= endMonth; i++) {
        // eslint-disable-next-line default-case
        switch (i) {
            case 1:
                deliveryFrequency[i] = 'Every Week';
                break;
            case 2:
                deliveryFrequency[i] = 'Every Other Week';
                break;
            case 4:
                deliveryFrequency[i] = 'Every Month';
                break;
            case 8:
                deliveryFrequency[i] = 'Every Other Month';
                break;
            case 12:
                deliveryFrequency[i] = 'Every Three Month';
                break;
        }
    }
    return deliveryFrequency;
};

export const getProductCount = () => {
    const startingcount = 0;
    const endCount = 10;
    const ProductCount = {};
    // eslint-disable-next-line no-plusplus
    for (let i = startingcount; i <= endCount; i++) {
        ProductCount[i] = i;
    }
    return ProductCount;
};

export const calculateProductAmount = (queantity, price) => {
    const qty = queantity || parseFloat('0.00').toFixed(2);
    const unitPrice = price || parseFloat('0.00').toFixed(2);
    const amount = Math.round(qty * unitPrice * 100) / 100;
    const amountToDisplay = '$'.concat(amount);
    return amountToDisplay;
};

export const calculateProductAmountInDecimal = (queantity, price) => {
    const qty = queantity || parseFloat('0.00').toFixed(2);
    const unitPrice = price || parseFloat('0.00').toFixed(2);
    const amount = Math.round(qty * unitPrice * 100) / 100;
    return amount;
};

export function convertISODateToDate(dateISO) {
    const date = new Date(dateISO);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = `0${dt}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }

    const convertedDate = `${year}-${month}-${dt}`;
    return convertedDate;
}

export function convertDateToISOFormat(date) {
    const dateInISOFormat = new Date(date).toISOString();
    return dateInISOFormat;
}

export const weekDays = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
};

export const weekDaysISO = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
};

export const dashbordStepsToRedirect = {
    1: '/home',
    2: '/my-bundle',
    3: '/my-membership',
    4: '/account-info',
    5: '/payment',
    6: '/support',
};

export const category = {
    1: 'All',
    2: 'Household Sundries',
    3: 'Cleaning',
    4: 'Personal Care',
    5: 'Laundry',
    6: 'Pantry',
};

export const formatAddressLine2 = (line2) => {
    if (!line2) {
        return '';
    }

    const prefix = !/^(unit|apt|suite|#)/i.test(line2) ? '#' : '';
    return ` ${prefix}${line2}`;
};

export const splitFullName = (fullName) => {
    if (!fullName) return undefined;

    const splitName = fullName.split(' ');
    return {
        firstName: splitName[0],
        lastName: splitName.slice(1).join(' '),
    };
};

export const getFormattedPhoneNumber = (phoneNumber) => {
    // Strip all characters from the input except digits
    let formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Trim the remaining input to ten characters, to preserve phone number format
    formattedPhoneNumber = formattedPhoneNumber.substring(0, 11);

    // Based upon the length of the string, we add formatting as necessary
    const size = formattedPhoneNumber.length;
    if (size === 0) {
        formattedPhoneNumber = '';
    } else if (size < 4) {
        formattedPhoneNumber = `(${formattedPhoneNumber}`;
    } else if (size < 7) {
        formattedPhoneNumber = `(${formattedPhoneNumber.substring(
            0,
            3,
        )}) ${formattedPhoneNumber.substring(3, 7)}`;
    } else {
        formattedPhoneNumber = `(${formattedPhoneNumber.substring(
            0,
            3,
        )}) ${formattedPhoneNumber.substring(
            3,
            7,
        )}-${formattedPhoneNumber.substring(7, 11)}`;
    }
    return formattedPhoneNumber;
};

export const getProductImagesUrls = (product) => {
    const images = [{ url: product?.images?.md, show_full_image: false }];
    return images.filter((image) => !!image.url);
};

export const isEmailValid = (email) =>
    String(email).toLowerCase().match(EMAIL_PATTERN);

/**
 * Format a given date in SQL to MMM dn-th format ('2022-01-01' => 'Jan 1st')
 *
 * @param {*} dateSql
 * @returns
 */
export const formatDateWithMonthAndOrdinal = (dateSql) => {
    const dateStr = DateTime.fromSQL(dateSql).toFormat('MMM');

    // TODO: refactor to use getOrdinalForDay util when migrated to typescript
    const pr = new Intl.PluralRules('en-US', {
        type: 'ordinal',
    });
    const suffixes = new Map([
        ['one', 'st'],
        ['two', 'nd'],
        ['few', 'rd'],
        ['other', 'th'],
    ]);
    const n = DateTime.fromSQL(dateSql).toFormat('d');
    const rule = pr.select(n);
    const suffix = suffixes.get(rule);
    return `${dateStr} ${n}${suffix}`;
};

export const getPriceString = (price, subscriptionPrice) => {
    if (!price && !subscriptionPrice) return '';
    return subscriptionPrice && subscriptionPrice > 0
        ? `${formatMoney(subscriptionPrice)}/month`
        : formatMoney(price);
};
