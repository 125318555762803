/**
 * Formats a numeric amount as a currency string in USD
 *
 * @param amount - The value to format as currency.
 * @param [options] - An optional object containing formatting options
 * @param [options.stripTrailingZeros=false] - If true, trailing zeros after the decimal point
 * will be removed for whole numbers
 *
 * @returns A formatted currency string
 */
export function formatMoney(
    amount: number | bigint,
    options = { stripTrailingZeros: false },
): string {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // NOTE: In the future we can use the `trailingZeroDisplay` option
        //       once it has better browser support
        maximumFractionDigits:
            options.stripTrailingZeros && Number.isInteger(amount) ? 0 : 2,
        minimumFractionDigits:
            options.stripTrailingZeros && Number.isInteger(amount) ? 0 : 2,
    });

    return currencyFormatter.format(amount);
}
