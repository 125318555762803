import { USD_CURRENCY } from '@scenes/Onboarding/constants/analyticsConstants';
import { getProductsAsGAProducts } from '@scenes/Onboarding/functions/product/getProductsAsGAProducts/getProductsAsGAProducts';
import { sumProducts } from '@scenes/Onboarding/functions/product/sumProducts/sumProducts';
import { Product, TrackId, UTM } from '@scenes/Onboarding/types';

export const SegmentPaymentPageStart = (
    email: string | undefined,
    onboardingTrack: TrackId,
    refillProducts: Product[] | null,
    utm: UTM | undefined,
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track(
            'Checkout Started',
            {
                email,
                onboardingTrack,
                currency: USD_CURRENCY,
                value: sumProducts(refillProducts || []),
                products: getProductsAsGAProducts(refillProducts || []),
            },
            utm,
        );
    // This is a segment standard event, be careful changing!
};
