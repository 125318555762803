import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import styles from './layoutHeaderActions.module.scss';

interface LayoutHeaderActionsProps {
    headerText: string;
    leftAction?: () => void;
    leftHeaderIcon?: React.ReactNode;
    rightAction?: () => void;
    rightHeaderIcon?: React.ReactNode;
    layout?: 'navigation' | 'title';
    Tooltip?: React.ReactNode | null;
}

const LayoutHeaderActions = ({
    headerText,
    leftHeaderIcon,
    leftAction,
    rightHeaderIcon,
    rightAction,
    layout = 'navigation',
    Tooltip,
}: LayoutHeaderActionsProps) => {
    // Provide a way to detect if the user is at the top of the page, so we can render sticky header with padding based
    // on the where user is on the page
    const [isAtTopOfPage, setIsAtTopOfPage] = useState<boolean>(true);
    useEffect(() => {
        window.onscroll = () => {
            if (window.scrollY === 0) {
                setIsAtTopOfPage(true);
            } else if (window.scrollY > 0 && isAtTopOfPage) {
                setIsAtTopOfPage(false);
            }
        };
        //  clean up callback on onscroll
        return () => {
            window.onscroll = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderLeftSpacer = () =>
        layout === 'title' ? null : <span aria-hidden />;

    return (
        <div
            className={clsx(styles.header, {
                [styles.navigation]: layout === 'navigation',
                [styles.title]: layout === 'title',
                [styles.withStickyPadding]: !isAtTopOfPage,
            })}
        >
            {leftHeaderIcon ? (
                <span
                    className={clsx(styles.headerText, styles.headerIcon)}
                    onClick={leftAction}
                >
                    <span className={styles.headerAction}>
                        {leftHeaderIcon}
                    </span>
                </span>
            ) : (
                // placeholder for left header icon to make keep grid layout
                renderLeftSpacer()
            )}
            <span
                className={clsx(styles.headerText, {
                    [styles.title]: layout === 'title',
                })}
            >
                {headerText}
                {Tooltip && Tooltip}
            </span>
            {rightHeaderIcon ? (
                <span
                    className={clsx(styles.headerText, styles.headerIcon)}
                    onClick={rightAction}
                >
                    <span className={styles.headerAction}>
                        {rightHeaderIcon}
                    </span>
                </span>
            ) : (
                // placeholder for left header icon to make keep grid layout
                <span aria-hidden />
            )}
        </div>
    );
};

export { LayoutHeaderActions };
