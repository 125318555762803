import clsx from 'clsx';

import $onboardingButton from './onboardingButton.module.scss';

interface OnboardingButtonProps {
    children: React.ReactNode;
    dataTestId?: string;
    disabled?: boolean;
    id: string;
    loading?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (args: any) => void;
    variant: 'tangerine' | 'charcoal';
}

const OnboardingButton = ({
    children,
    dataTestId,
    disabled,
    id,
    loading,
    onClick,
    variant,
}: OnboardingButtonProps) => {
    return (
        <div className={clsx($onboardingButton.container)}>
            <button
                className={clsx(
                    $onboardingButton.button,
                    {
                        [$onboardingButton.tangerine]: variant === 'tangerine',
                    },
                    {
                        [$onboardingButton.charcoal]: variant === 'charcoal',
                    },
                    {
                        [$onboardingButton.loading]: loading,
                    },
                )}
                data-testid={dataTestId}
                disabled={disabled}
                id={id}
                onClick={onClick}
                type="button"
            >
                {children}
            </button>
        </div>
    );
};

export { OnboardingButton };
