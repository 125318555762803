import { Paper } from '@mui/material';

import $resultsBackground from './resultsBackground.module.scss';

interface MobileHeaderSearchbarResultsBackgroundProps {
    children?: React.ReactNode;
}

const MobileHeaderSearchbarResultsBackground = ({
    children,
}: MobileHeaderSearchbarResultsBackgroundProps) => {
    return (
        <Paper className={$resultsBackground.searchResultsBackground}>
            {children}
        </Paper>
    );
};

export { MobileHeaderSearchbarResultsBackground };
