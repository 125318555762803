import { useQuery } from '@apollo/client';
import { noop } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';

import { useAuthContext } from '@contexts/AuthContext';
import { AUTOPILOT_QUERY } from '@custom-queries/autopilot';

import { type AutopilotContextShape } from './types';

const emptyDefault: AutopilotContextShape = {
    state: 'loading',
    loading: true,
    refresh: noop,
    findAutopilotProduct: () => undefined,
};

const AutopilotContext =
    React.createContext<AutopilotContextShape>(emptyDefault);

const useAutopilotContext = () => useContext(AutopilotContext);

const AutopilotProvider = ({ children }: React.PropsWithChildren) => {
    const { isLoggedIn } = useAuthContext();

    const autopilotQuery = useQuery(AUTOPILOT_QUERY, {
        fetchPolicy: 'cache-and-network',
        returnPartialData: true,
        skip: !isLoggedIn,
    });

    const findAutopilotProduct = useCallback(
        (productId: number) => {
            return autopilotQuery.data?.userBundleProducts.find(
                (autopilotProduct) =>
                    autopilotProduct.product?.id === productId,
            );
        },
        [autopilotQuery.data],
    );

    const contextProviderValue: AutopilotContextShape = useMemo(() => {
        if (!isLoggedIn) {
            return {
                ...emptyDefault,
                loading: false,
                state: 'incomplete',
            };
        }
        if (autopilotQuery.loading && !autopilotQuery.data) {
            return {
                loading: true,
                state: 'loading',
                refresh: () => {
                    autopilotQuery.refetch(); // NOTE: this is async
                },
                findAutopilotProduct,
            };
        }
        return {
            loading: autopilotQuery.loading,
            state: 'complete',
            userBundleProducts: autopilotQuery.data?.userBundleProducts ?? [],
            refresh: () => {
                autopilotQuery.refetch(); // NOTE: this is async
            },
            findAutopilotProduct,
        };
    }, [autopilotQuery, isLoggedIn, findAutopilotProduct]);

    return (
        <AutopilotContext.Provider value={contextProviderValue}>
            {children}
        </AutopilotContext.Provider>
    );
};
export { AutopilotProvider, useAutopilotContext };
