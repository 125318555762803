import { graphql } from '@gql/gql';

export const REFILLS_QUERY = graphql(`
    query RefillsList($nowDate: DateString!) {
        orderIntents(limit: 3, afterOrOnDate: $nowDate) {
            id
            expectedDeliveryDate
            status
            lockedAt
            scheduledToLockAt
            cost {
                subtotal
                credit
                coupon
                estimatedTax
                estimatedTotal
            }
            minimumOrderValueThreshold
            orderIntentProducts {
                id
                quantity
                sellableEntityVariationId
                product {
                    id
                    name
                    price
                    subscription_price
                    retail_price
                    service_type
                    sellable_qty
                    sellable_count
                    sellable_unit
                    images {
                        sm
                    }
                    primary_category {
                        display_name
                        slug
                    }
                    top_level_category {
                        display_name
                        slug
                    }
                }
            }
        }
    }
`);

export const UPDATE_ORDER_INTENT_PRODUCT_MUTATION = graphql(`
    mutation UpdateRefillProduct($input: UpdateOrderIntentProductInput!) {
        updateOrderIntentProduct(input: $input) {
            id
            quantity
            sellableEntityVariationId
        }
    }
`);

export const REFILL_PRODUCT_PREFERENCES_SHEET_QUERY = graphql(`
    query RefillProductPreferencesSheet(
        $sellableEntityVariationId: Int!
        $nowDate: DateString!
    ) {
        orderIntents(limit: 6, afterOrOnDate: $nowDate, excludeLocked: true) {
            id
            expectedDeliveryDate
        }

        userBundleProduct(
            sellableEntityVariationId: $sellableEntityVariationId
        ) {
            id
            quantity
            frequency
            nextDeliveryDate
            nextOrderIntentId
        }

        getProductV2(id: $sellableEntityVariationId) {
            product {
                id
                name
                price
                subscription_price
                sellable_qty
                sellable_count
                sellable_unit
                service_type
                images {
                    sm
                }
            }
        }
    }
`);

export const SKIP_REFILL_MUTATION = graphql(`
    mutation SkipRefill($input: SkipOrderIntentInput!) {
        skipOrderIntent(input: $input) {
            id
            status
        }
    }
`);

export const UNSKIP_REFILL_MUTATION = graphql(`
    mutation UnskipRefill($input: UnskipOrderIntentInput!) {
        unskipOrderIntent(input: $input) {
            id
            expectedDeliveryDate
            status
        }
    }
`);

export const UPDATE_REFILL_DAY_MUTATION = graphql(`
    mutation MutationUpdateUserRefillDayArgs(
        $input: UpdateUserRefillDayInput!
    ) {
        updateUserRefillDay(input: $input) {
            userId
            refillDay
        }
    }
`);

export const REFILL_DAY_AVAILABILITY_QUERY = graphql(`
    query RefillAvailability($input: RefillAvailabilityInput!) {
        refillAvailability(input: $input) {
            refillDetails {
                defaultRefillDay {
                    dayOfWeek
                    lockDayOfWeek
                    lockTimeFormatted
                }
                refillDayOptions {
                    dateOfFirstRefill
                    dayOfWeek
                    lockDayOfWeek
                    lockTimeFormatted
                }
            }
        }
    }
`);
