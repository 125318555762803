interface ProductReviewPromptClickedProperties {
    orderId: number;
    action: 'review products' | 'maybe later';
}
export const trackProductReviewPromptClicked = ({
    orderId,
    action,
}: ProductReviewPromptClickedProperties) => {
    return window?.analytics?.track('Product Review Prompt Clicked', {
        order_id: orderId,
        origin: 'post delivery',
        action,
    });
};
