import { useFeatureFlagPayload } from 'posthog-js/react';

import { FLAGS } from '@utilities/constants/featureFlags';

// Structure of each tier of the MOV promo
export type MovPromo = {
    orderValue: number;
    discount: number;
};

/**
 * Example tiered MOV promo JSON payload
 *    {
 *     "minimumOffsetFromLowestTier": 10,
 *     "promos" :
 *       [
 *         {
 *           "orderValue":50,
 *           "discount":10
 *         },
 *         {
 *           "orderValue":100,
 *           "discount":30
 *         }
 *       ]
 *    }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TieredMovPromoDetails extends Record<string, any> {
    minimumOffsetFromLowestTier?: number; // Amount below first tier needed to start showing dynamic messaging
    promos: MovPromo[];
}

// Gets payload for tiered mov promo feature flag
const useTieredMovPromo = (): TieredMovPromoDetails | null => {
    const payload = useFeatureFlagPayload(FLAGS.TIERED_MOV_PROMO.id);
    if (!(payload as TieredMovPromoDetails)?.promos) {
        return null;
    }
    return payload as TieredMovPromoDetails;
};

export { useTieredMovPromo };
