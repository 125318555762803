import { datadogRum } from '@datadog/browser-rum';
import { PostHogProvider } from 'posthog-js/react';
import React, { Component } from 'react';

interface PostHogProviderWrapperProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    client: any;
    children: React.ReactNode;
}

class PostHogProviderWrapper extends Component<PostHogProviderWrapperProps> {
    override componentDidMount() {
        const { client } = this.props;
        if (!client) {
            datadogRum.addError(
                new Error('PostHog client is null or undefined'),
            );
            if (!client.segmentIntegration?.isLoaded()) {
                datadogRum.addError(
                    new Error(
                        'PostHog client does not include Segment integration',
                    ),
                );
            }
        }
    }

    override render() {
        const { client, children } = this.props;
        return <PostHogProvider client={client}>{children}</PostHogProvider>;
    }
}

export default PostHogProviderWrapper;
