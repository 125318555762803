import loadGoogleMapsApi from 'load-google-maps-api';
import { createContext, useContext, useEffect, useState } from 'react';

export const GoogleMapsContext = createContext({});
export const useGoogleMapsContext = () => useContext(GoogleMapsContext);

type GoogleMapsProviderProps = {
    apiKey: string;
    children: React.ReactNode;
};

// Loads the Google Maps SDK and provides status
export const GoogleMapsProvider = ({
    apiKey,
    children,
}: GoogleMapsProviderProps) => {
    const [mapsSdkReady, setMapsSdkReady] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                await loadGoogleMapsApi({
                    key: apiKey,
                    libraries: ['places'],
                });
                setMapsSdkReady(true);
            } catch (err) {
                console.error(err);
            }
        })();
    }, [apiKey]);

    return (
        <GoogleMapsContext.Provider value={mapsSdkReady}>
            {children}
        </GoogleMapsContext.Provider>
    );
};
