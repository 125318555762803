import { useLazyQuery } from '@apollo/client';

import { SEARCH_RECOMMENDED_TERM } from '@custom-queries/dashboard';
import { SearchTermSuggestionsQuery } from '@gql/graphql';
import { ErrorService } from '@services/error';

const useFetchRecommendedTerms = () => {
    const [fetchRecommendedTerm, { loading, data, error: apolloError }] =
        useLazyQuery<SearchTermSuggestionsQuery>(SEARCH_RECOMMENDED_TERM, {
            onError: (error) => {
                ErrorService.captureError(
                    new Error('Error searching recommended term'),
                    {
                        error,
                        apolloError,
                    },
                );
            },
        });

    return {
        fetchRecommendedTerm,
        loading,
        error: apolloError,
        results: data?.searchTermSuggestions?.searchSuggestionList || [],
        queryId: data?.searchTermSuggestions?.queryId || undefined,
    };
};

export { useFetchRecommendedTerms };
