import type { QueryBundleProduct } from '@scenes/Dashboard/Autopilot/types';

export function formatSellableQuantity({
    sellableCount,
    sellableQuantity,
    sellableUnit,
}: {
    sellableCount: number;
    sellableQuantity: number;
    sellableUnit?: string;
}): string {
    let productQuantityText = '';

    if (sellableCount && sellableCount > 1) {
        if (sellableQuantity && sellableQuantity >= 1) {
            productQuantityText = `${
                sellableCount && sellableCount
            } × ${sellableQuantity} ${sellableUnit || 'ct'}`;
        } else {
            productQuantityText = `${sellableCount && sellableCount} ${
                sellableUnit || 'ct'
            }`;
        }
    } else if (
        sellableQuantity &&
        sellableQuantity > 0 &&
        sellableUnit !== null
    ) {
        productQuantityText = `${sellableQuantity} ${sellableUnit || 'ct'}`;
    }

    return productQuantityText;
}

export function getProductSize(bundleProduct: QueryBundleProduct) {
    return formatSellableQuantity({
        sellableCount: bundleProduct.product.sellable_count ?? 0,
        sellableQuantity: bundleProduct.product.sellable_qty ?? 0,
        sellableUnit: bundleProduct.product.sellable_unit,
    });
}
