import { FrequencySelectionMethod } from '@gql/graphql';

import { AnalyticsOriginProperties, CLICK_ORIGINS } from '../constants';

export const INVENTORY_PRODUCT_USER_ACTIONS = {
    ADD_USER_ACTION: 'ADD',
    UPDATE_USER_ACTION: 'UPDATE',
    REMOVE_USER_ACTION: 'REMOVE',
} as const;

type InventoryProductUserActions =
    (typeof INVENTORY_PRODUCT_USER_ACTIONS)[keyof typeof INVENTORY_PRODUCT_USER_ACTIONS];

export type ProductActionClickedProps = {
    action: InventoryProductUserActions;
    frequency_option_selected?: FrequencySelectionMethod;
    frequency: number;
    isSuggestion?: boolean;
    origin: AnalyticsOriginProperties;
    productId: number;
    quantity: number;
    cityId: number; // used to send event to proper Algolia index
};

/**
 * User has clicked Inventory add product button
 */
export const ProductActionClicked = (props: ProductActionClickedProps) => {
    let referrer;
    let pdpClickedOrigin;
    if (sessionStorage) {
        referrer = sessionStorage.getItem('referrer');
        // This is to only track the origin of the click on the PDP
        if (
            props.origin.origin === CLICK_ORIGINS.PDP ||
            props.origin.origin === CLICK_ORIGINS.PDP_ADD_ON ||
            props.origin.origin === CLICK_ORIGINS.PDP_RECOMMENDATION
        ) {
            pdpClickedOrigin = sessionStorage.getItem('pdpClickedOrigin');
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track(
            'Product Action Clicked',
            {
                ...props,
                ...props.origin,
                pdpClickedOrigin,
            },
            {
                context: {
                    page: {
                        referrer,
                    },
                },
            },
        );

    // clean up 'pdpClickedOrigin' in session storage
    sessionStorage.removeItem('pdpClickedOrigin');
};
