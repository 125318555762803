import { Product } from '@scenes/Onboarding/types';

/**
 * Takes in a list of products and maps each product to have this data structure:
 * {
 *  product_id: 123,
 *  name: "Soap",
 *  price: $2.00,
 *  quantity: 1
 * }
 */
const getProductsAsGAProducts = (products: Product[]) => {
    if (products) {
        return products.map((product) => ({
            product_id: product.id,
            name: product.name,
            price: product.price,
            quantity: 1, // hardcoding 1 for now as most products will get that as default, and the way we do predictions needs to change anyways
        }));
    }
    return [];
};

export { getProductsAsGAProducts };
