interface ProductReviewDrawerViewedProperties {
    sellableEntityVariationIds: number[];
    origin: 'remove from autopilot' | 'post delivery';
    orderId?: number;
}
export const trackProductReviewDrawerViewed = ({
    sellableEntityVariationIds,
    origin,
    orderId,
}: ProductReviewDrawerViewedProperties) => {
    return window?.analytics?.track('Product Review Drawer Viewed', {
        sellable_entity_variation_ids: sellableEntityVariationIds,
        origin,
        order_id: orderId,
    });
};
