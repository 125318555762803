export function productPath(productId: number): string {
    return `/dashboard/product?product_id=${productId}`;
}

/**
 * Parses the query string from a path and returns an object
 * @param {string} path - The path to parse. Must include `?` in the string
 * @returns {Record<string, string>} - The object from the query string
 */
export function parseQueryString(path: string): Record<string, string> {
    return path.includes('?')
        ? Object.fromEntries(new URLSearchParams(path.split('?')[1]).entries())
        : {};
}
