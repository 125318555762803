import React from 'react';

import $dashboardLayout from './layoutHeaderText.module.scss';

interface LayoutHeaderTextProps {
    headerText: string;
}

const LayoutHeaderText = ({ headerText }: LayoutHeaderTextProps) => {
    return <p className={$dashboardLayout.headerText}>{headerText}</p>;
};

export { LayoutHeaderText };
