import { useMemo } from 'react';

import { useRefillContext } from '@contexts/RefillsContext';
import { useTieredMovPromo } from '@custom-hooks/featureFlag/useTieredMovPromo';
import { getTieredMovPromoBannerCopy } from '@utilities/movPromo/getTieredMovPromoBannerCopy';

import { AlertBannerModal } from './types';

const usePosthogTieredMovPromoBanner = (): AlertBannerModal => {
    const payload = useTieredMovPromo();
    const refills = useRefillContext();

    const body = useMemo(() => {
        if (refills.state === 'complete') {
            const nowRefil = refills.now;
            if (payload?.promos && !!nowRefil?.total) {
                return getTieredMovPromoBannerCopy(
                    payload?.promos,
                    nowRefil?.total,
                    payload?.minimumOffsetFromLowestTier,
                    nowRefil?.status === 'USER_SKIPPED',
                );
            }
        }
        return null;
    }, [payload, refills]);

    return {
        banner: {
            enabled: !!payload && !!body,
            color: 'tangerine',
            body: body?.message ?? '',
            showCloseIcon: false,
            onClose: () => {},
            cta: body?.cta,
        },
        modal: {
            enabled: false,
        },
        ctaLink: body?.ctaLink,
    };
};

export { usePosthogTieredMovPromoBanner };
