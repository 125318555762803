import type { CSSProperties } from 'react';

import type { CueName, CueOffset, CueStatus } from '@type/cues';

import { CARET_SIZE } from './constants';

export function trackViewedCue(name: CueName) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Viewed Cue', { name });
}

export function trackDismissedCue(name: CueName) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window &&
        window.analytics &&
        window.analytics.track('Dismissed Cue', { name });
}

type CaretOffset = number;
type CueStyles = CSSProperties;

export function computeStyles(
    anchorEl: Element,
    targetEl: Element | null,
    offset?: CueOffset,
): [CueStyles, CaretOffset] {
    const elStyles = anchorEl.getBoundingClientRect();
    const {
        bottom: anchorBottom = 0,
        left: anchorLeft = 0,
        width: anchorWidth = 0,
    } = elStyles;

    const { top: offsetTop = 0 } = offset ?? {};

    const targetElStyles = targetEl?.getBoundingClientRect();
    const { left: targetLeft = 0 } = targetElStyles ?? {};

    // prettier-ignore
    const top =
        anchorBottom // the bottom left of the element we want to position against
      + Math.hypot(CARET_SIZE, CARET_SIZE) / 2 // the height of the caret
      + offsetTop; // a custom amount to offset by (defaults to 0)

    const cueStyles = { top: `${top}px` };

    // prettier-ignore
    const caretPosition =
        (anchorLeft - targetLeft)
      + (anchorWidth / 2)
      - (CARET_SIZE / 2)

    return [cueStyles, caretPosition];
}

export function mapCueDisplayStyle(status: CueStatus) {
    return status === 'visible' ? 'block' : 'none';
}
