class ReactRenderingError extends Error {
    override cause: Error;

    constructor(message: string, stack: string, cause: Error) {
        super(message);
        this.name = 'ReactRenderingError';
        this.stack = stack;
        this.cause = cause;
    }
}

export { ReactRenderingError };
