import Link from 'next/link';
import SearchIcon from 'public/icons/small/icon-small-search.svg';

import { trackSearchSuggestionClicked } from '@utilities/tracking/search/searchedProducts/trackSearchSuggestionClicked';

import $searchResultStyles from './SearchbarResultTerms.module.scss';

interface SearchbarResultTermsProps {
    source: string;
    term: string;
    queryId?: string;
}

const SearchbarResultTerms = ({
    source,
    term,
    queryId,
}: SearchbarResultTermsProps) => {
    return (
        <Link href={`/search?query=${term}`} legacyBehavior>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
                key={term}
                onClick={() =>
                    trackSearchSuggestionClicked({
                        query: term,
                        queryId,
                        source,
                    })
                }
            >
                <div className={$searchResultStyles.option}>
                    <SearchIcon />
                    <span>{term}</span>
                </div>
            </a>
        </Link>
    );
};

export { SearchbarResultTerms };
