// Matches all pages under /discover and /discover/* with optional query parameters
const discoverPagesUrlRegex = /^\/discover(\/\w+)?/;
const cureatedCollectionPagesUrlRegex = /^\/collection(\/\w+)?/;

// Matches all pages under /dashboard and /dashboard/* with optional query parameters
const dashboardPagesUrlRegex = /^\/dashboard(\/\w+)?/;

// Matches all pages under /onboarding and /onboarding/* with optional query parameters
const onboardingPagesUrlRegex = /^\/onboarding(\/\w+)?/;

// Matches all pages under /search and /search/* with optional query parameters
const searchPageUrlRegex = /^\/search(\/\w+)?/;

export {
    cureatedCollectionPagesUrlRegex,
    discoverPagesUrlRegex,
    dashboardPagesUrlRegex,
    onboardingPagesUrlRegex,
    searchPageUrlRegex,
};
