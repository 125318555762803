import { TrackId } from '@scenes/Onboarding/types';
import { ErrorService } from '@services/error';

export const identifyMarketGrowthLead = (
    email: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
    phoneNumber: string | undefined,
    trackId: TrackId,
) => {
    let anonymousId;
    try {
        anonymousId = window.analytics.user().anonymousId().toString();
    } catch (error) {
        ErrorService.captureError(
            new Error(
                `Error accessing window.analytics.user(): ${JSON.stringify(
                    error,
                )}`,
            ),
        );
    }
    window?.analytics?.identify(
        {
            email,
            first_name: firstName,
            is_lead: true,
            last_name: lastName,
            onboarding_track: trackId,
            phone_number: phoneNumber,
        },
        {
            anonymousId,
        },
    );
};
